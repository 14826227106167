* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
img {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
::-webkit-scrollbar-thumb {
    background: #888;
    width: 4px;
}
::-webkit-scrollbar-track {
    background: inherit;
    display: none;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
::-webkit-scrollbar {
    width: 3px;
    display: none;
}
::-webkit-scrollbar {
    /* width: 1 !important; */
    display: none;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:before,
blockquote:after {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
body {
    font-size: 1.0625rem;
    color: #222222;
    background: #f9fbfd;
    overflow-x: hidden;
    min-width: 20rem;
    position: relative;
    font-family: "dm_sansregular";
}
html {
    overflow-x: hidden;
}
input,
textarea,
select,
::placeholder {
    font-family: "dm_sansregular";
    background: unset;
    border: unset;
}
button {
    font-family: "dm_sansregular";
}
textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

*:focus {
    outline: none;
}
a {
    color: #1a77b2;
}
.submit:hover {
    filter: alpha(opacity=85);
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
    width: 100%;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    /* font-size: 1rem; */
    color: #fff;
}
br.clear {
    clear: both;
    line-height: 0;
}
/* .slick-arrow {
	display: none !important;
} */

/* fonts */

@font-face {
    font-family: "dm_sansbold";
    src: url("../fonts/dmsans-bold-webfont.woff") format("woff2"),
        url("../fonts/dmsans-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "dm_sansmedium";
    src: url("../fonts/dmsans-medium-webfont.woff2") format("woff2"),
        url("../fonts/dmsans-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "dm_sansregular";
    src: url("../fonts/dmsans-regular-webfont.woff2") format("woff2"),
        url("../fonts/dmsans-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* common */

h3 {
    font-size: 24px;
}
h4 {
    font-size: 20px;
}
h5 {
    font-size: 16px;
}
h6 {
    font-size: 14px;
}
h2 {
    font-size: 18px;
}
small {
    font-size: 13px;
    display: inline-block;
}
p {
    font-size: 16px;
    color: #555a5a;
    line-height: 145%;
}
.p-20 {
    padding: 20px;
}
@media (max-width: 1080px) {
    h2 {
        font-size: 16px;
    }
}
@media all and (max-width: 980px) {
    .accordianSpecial {
        font-size: 1.1rem !important;
    }
}

@media (max-width: 640px) {
    h2 {
        font-size: 16px;
    }
    p {
        font-size: 15px;
    }
    h3 {
        font-size: 22px;
    }
    h6 {
        font-size: 13px;
    }
    h4 {
        font-size: 18px;
    }
}
@media (max-width: 480px) {
    h6 {
        font-size: 12px;
    }
    p {
        font-size: 14px;
    }
    h4 {
        font-size: 16px;
    }
    h3 {
        font-size: 18px;
    }
}

.slick-slide {
    /* width: 400px !important; */
}
.wrapper {
    max-width: 1280px;
    width: 82%;
    margin: 0 auto;
}
@media all and (max-width: 1280px) {
    .wrapper {
        width: 85%;
    }
}
@media all and (max-width: 640px) {
    .wrapper {
        width: 90%;
    }
}
.map-container {
    height: 80vh !important;
}

/* Google autocomplete search bar custom */
.pac-container {
    background: #fff !important;
    box-shadow: none;
    border: none !important;
    margin-top: 10px;
    z-index: 4000 !important;
}
.pac-icon {
    display: none;
}
.pac-item {
    border: none !important;
    border-bottom: 1px solid #f3f4f5 !important;
    padding: 10px !important;
    font-family: "dm_sansregular" !important;
}

.slick-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
}
html.modal-enabled {
    overflow: hidden !important;
}
